import React, { useState } from 'react';
import { styled } from 'styled-components';
import { darkGreen } from '../../StyleVariables';
import { GridWhite, Spacer } from '../../components/Common';

interface Props {
    isMobile: boolean,
    goToLoginPage: () => void,
    inquiry: any,
}

export default function Step4 ({ isMobile, goToLoginPage, inquiry }: Props) {
    return (
        <div>
            <Spacer/>
            <Border isMobile={isMobile}>
            {/* <GridWhite container> */}
                <Title isMobile={isMobile}>株主優待のお申し込みを受け付けました</Title>
                
                <Content isMobile={isMobile}>ご入力頂いたメールアドレス宛に、お申込み完了のご案内を差し上げます。ご優待品の発送は2024年12月を予定しております。今しばらくお時間をいただきますようお願い申し上げます。</Content>

                <Line />

                <Smaller isMobile={isMobile}>お問合せ先</Smaller>
                
                <Content2 isMobile={isMobile}>
                    {isMobile ? 
                        inquiry ? inquiry.name : ''
                    :
                        inquiry ? inquiry.name : ''
                    }
                </Content2>
                <Content2 isMobile={isMobile}>
                    TEL: {isMobile ? 
                        inquiry ? inquiry.telephone : ''
                    :
                        inquiry ? inquiry.telephone : ''
                    }
                </Content2>
                <Content2 isMobile={isMobile}>
                    E-Mail: {isMobile ? 
                        inquiry ? inquiry.email : ''
                    :
                        inquiry ? inquiry.email : ''
                    }
                </Content2>
                <Content2 isMobile={isMobile}>
                    {isMobile ? 
                        inquiry ? inquiry.remarks : ''
                    :
                        inquiry ? inquiry.remarks : ''
                    }
                </Content2>
            {/* </GridWhite> */}
            </Border>
            <ButtonReturn onClick={goToLoginPage} isMobile={isMobile}>ログイン画面に戻る</ButtonReturn>
        </div>
    )
}

const Border = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    border: '1px solid #46965A',
    width: isMobile ? '350px' : '750px',
    height: 'auto',
    padding: '20px',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 195px);' : 'calc(50% - 395px);',
    marginTop: '50px'
}));

const Title = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#46965A',
    fontWeight: 'bold',
    fontSize: isMobile ? 20 : 32,
    textAlign: isMobile ? 'left' : 'center'
}));

const Content = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#000000',
    fontWeight: 'regular',
    fontSize: isMobile ? 14 : 20,
}));

const Line = styled("div")(({}) => ({
    borderTop: '1px solid #46965A',
    marginTop:'-10px',
    marginBottom: '10px'
}));

const Smaller = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontWeight: 'bold',
    fontSize: isMobile ? 12 : 16,
}));

const Content2 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontWeight: 'regular',
    fontSize: isMobile ? 12 : 16,
}));

const ButtonReturn = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: '#808080',
    fontWeight: 'bold',
    fontSize: isMobile ? 24 : 32,
    width: isMobile ? '350px' : '500px',
    height: isMobile ? '50px' : '80px',
    borderRadius: '10px',
    border: 'none',
    marginTop: '20px',
    marginBottom: '20px',
    position: 'relative',
    marginLeft: isMobile ? 'calc(50% - 175px)' : 'calc(50% - 250px)',
    cursor: 'pointer',
}));