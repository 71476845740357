import React from 'react';
import { styled } from 'styled-components';

interface Props {
    isMobile: boolean,
    goToLoginPage: () => void,
    inquiry: any,
}

export default function EndViewHome ({ isMobile, goToLoginPage, inquiry }: Props) {
    return (
        <div>
            <Border isMobile={isMobile}>
                <Title isMobile={isMobile}>デリカフーズホールディングス株式会社株主優待お申込みサイト</Title>
                
                <Content isMobile={isMobile}>本年度の株主優待の受付は終了致しました。株主優待に関するお問い合わせは弊社担当窓口までお願い致します。</Content>

                <Line />
                
                <Content2 isMobile={isMobile}>
                    {isMobile ? 
                        inquiry ? inquiry.name : ''
                    :
                        inquiry ? inquiry.name : ''
                    }
                </Content2>
                <Content2 isMobile={isMobile}>
                    TEL: {isMobile ? 
                        inquiry ? inquiry.telephone : ''
                    :
                        inquiry ? inquiry.telephone : ''
                    }
                </Content2>
                <Content2 isMobile={isMobile}>
                    E-Mail: {isMobile ? 
                        inquiry ? inquiry.email : ''
                    :
                        inquiry ? inquiry.email : ''
                    }
                </Content2>
                <Content2 isMobile={isMobile}>
                    {isMobile ? 
                        inquiry ? inquiry.remarks : ''
                    :
                        inquiry ? inquiry.remarks : ''
                    }
                </Content2>
            </Border>
            <ButtonReturn onClick={goToLoginPage} isMobile={isMobile}>ログイン画面に戻る</ButtonReturn>
        </div>
    )
}

const Border = styled("div")<{isMobile: boolean}>(({isMobile}) => ({
    border: '1px solid #46965A',
    width: isMobile ? '350px' : '750px',
    height: 'auto',
    padding: '20px',
    position: 'relative',
    margin: '0px',
    marginLeft: isMobile ? 'calc(50% - 195px);' : 'calc(50% - 395px);',
    marginTop: '50px'
}));

const Title = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#46965A',
    fontWeight: 'bold',
    fontSize: isMobile ? 20 : 32,
    textAlign: isMobile ? 'left' : 'center'
}));

const Content = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: '#000000',
    fontWeight: 'regular',
    fontSize: isMobile ? 14 : 20,
}));

const Line = styled("div")(({}) => ({
    borderTop: '1px solid #46965A',
    marginTop:'-10px',
    marginBottom: '10px'
}));

const Content2 = styled("p")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'black',
    fontWeight: 'regular',
    fontSize: isMobile ? 12 : 16,
}));

const ButtonReturn = styled("button")<{isMobile: boolean}>(({isMobile}) => ({
    color: 'white',
    backgroundColor: '#808080',
    fontWeight: 'bold',
    fontSize: isMobile ? 24 : 32,
    width: isMobile ? '350px' : '500px',
    height: isMobile ? '50px' : '80px',
    borderRadius: '10px',
    border: 'none',
    marginTop: '20px',
    marginBottom: '20px',
    position: 'relative',
    marginLeft: isMobile ? 'calc(50% - 175px)' : 'calc(50% - 250px)',
    cursor: 'pointer',
}));