import React from 'react';

interface Props {
    active?: number
}

export default function BreadCrumbs ({ active = 1 }: Props) {
    return (
        <div className="multi-step">
            <ul className="multi-step-list">
                <li className={`multi-step-item longer ${active === 1 ? 'current' : ''}`}>
                    <div className="item-wrap" style={{marginRight: '10px'}}>
                        <p className="item-subtitle">Step 1</p>
                        <p className="item-title">ご優待品</p>
                    </div>
                </li>
                <li className={`multi-step-item longer ${active === 2 ? 'current' : ''}`}>
                    <div className="item-wrap">
                        <p className="item-subtitle">Step 2</p>
                        <p className="item-title">送付先入力</p>
                    </div>
                </li>
                <li className={`multi-step-item ${active === 3 ? 'current' : ''}`}>
                    <div className="item-wrap">
                        <p className="item-subtitle">Step 3</p>
                        <p className="item-title">確認</p>
                    </div>
                </li>
                <li className={`multi-step-item ${active === 4 ? 'current' : ''}`}>
                    <div className="item-wrap">
                        <p className="item-subtitle">Step 4</p>
                        <p className="item-title">完了</p>
                    </div>
                </li>
            </ul>
        </div>
    )
}