import React, { useEffect, useState } from 'react';
import useMediaQuery from "./hooks/userMediaQuery";
import Login from './pages/Login';
import Home from './pages/Home';
import Footer from './components/Footer';
import Header from './components/Header';
import ChooseGift from './pages/ChooseGift';
import NotFound from './pages/NotFound';
import EndViewHome from './pages/EndViewHome';
import BeforeScheduleView from './pages/BeforeScheduleView';
import StaticView from './pages/StaticView';
import moment from 'moment';

interface AppProps {
    history?: any,
    location?: any
}

const RoutesHook = ( { history } : AppProps ) => {

    const isMobile = useMediaQuery('(max-width: 768px)');

    const location = history ? history.location.pathname : null;

    const [ token, setToken ] = useState<string>("");
    const [ user, setUser ] = useState<any|null>(null);
    const [ scheduleStartDate, setScheduleStartDate ] = useState<any|null>("");
    const [ scheduleEndDate, setScheduleEndDate ] = useState<string>("");
    const [ prefectures, setPrefectures ] = useState<string>("");
    const [ inquiry, setInquiry ] = useState<any | null>(null);
    const [ isChecked, setIsChecked ] = useState<any | null>(false);

    const fetchServer = async (url: any, method: any, body: any, headers: any, forwardRes: any) => {
        let headersAuth = token ? {
          'Authorization': 'Bearer ' + token
        } : {};
        fetch(url, {
            method: method,
            body: body,
            headers: {
                ...headers,
                ...headersAuth,
                'X-Authcode': process.env.REACT_APP_AUTHCODE
            }
        }).then((response) => {
          forwardRes(response);
        })
    }

    function getSchedule () {
        fetchServer(
            process.env.REACT_APP_BASE_URL + '/v1/schedule', 
            'GET', 
            null, 
            null,
            async (response: any) => {
                response = await response.json();
                if(response.data.start_date && response.data.end_date){
                    setScheduleStartDate(response.data.start_date);
                    setScheduleEndDate(response.data.end_date);
                } else {
                    alert('Failed get schedule.');
                }
            }
        )
    }

    useEffect(()=>{
        if(!process.env.REACT_APP_IS_HARDCODE){
            getSchedule();
        }
    }, []);

    const goToLoginPage = () => {
        logout();
        history.push('/')
    }

    const goToHomePage = () => {
        history.push('/home')
    }

    const goToChooseGiftPage = () => {
        history.push('/choose-gift')
    }

    const goToEndViewPage = () => {
        history.push('/end')
    }

    const goToBeforeSchedulePage = () => {
        history.push('/info')
    }

    function getUser () {
        if(!token) return;
        fetchServer(
            process.env.REACT_APP_BASE_URL + '/v1/user', 
            'GET', 
            null, 
            null,
            async (response: any) => {
                response = await response.json();
                if(response.data){ //Success get user
                    setUser(response.data);
                }
            }
        )
    }

    function logout () {
        setToken("");
    }

    useEffect(() => {
        if(token){
            // login
            // getSchedule();
            getUser();
            getPrefectures();
            getInquiry();
        } else {
            // logout
            logout();
        }
    }, [token]);

    useEffect(() => {
        if(user){
            if(user.is_answered){ // IF USER ALREADY ANSWER THE FORM
                goToHomePage();
            } else if(scheduleStartDate && scheduleEndDate){ // IF HAVE START SCHEDULE DATE AND END SCHEDULE DATE
                const isScheduleAvailable = moment().isBetween(scheduleStartDate, scheduleEndDate);
                if(isScheduleAvailable){ // IF HAVE SCHEDULE AND USER HAS NOT ANSWER THE FORM
                    goToChooseGiftPage();
                }
                const isScheduleStart = moment().isBefore(scheduleStartDate);
                const isScheduleEnd = moment().isAfter(scheduleEndDate);
                if(isScheduleStart){ // IF SCHEDULE DOESN'T START
                    goToBeforeSchedulePage();
                } 
                if(isScheduleEnd) { // IF SCHEDULE END AND THE USER HAS NOT ANSWER THE FORM
                    goToEndViewPage();
                }
            }
        } 
    }, [scheduleStartDate, scheduleEndDate, user]);

    function getPrefectures () {
        fetchServer(
            process.env.REACT_APP_BASE_URL + '/v1/prefecture', 
            'GET', 
            null, 
            null,
            async (response: any) => {
                response = await response.json();
                if(response.data){
                    setPrefectures(response.data);
                } else {
                    alert('Failed get prefectures.');
                }
            }
        )
    }

    function getInquiry () {
        fetchServer(
            process.env.REACT_APP_BASE_URL + '/v1/inquiry', 
            'GET', 
            null, 
            null,
            async (response: any) => {
                response = await response.json();
                if(response.data){
                    setInquiry(response.data);
                } else {
                    alert('Failed get inqury.');
                }
            }
        )
    }

    return (
        <>
            <Header />
            <div style={{height: 'auto', paddingTop: '80px', minHeight: 'calc(100vh - 165px)'}}>
                {
                    location === '/' ?
                        process.env.REACT_APP_IS_HARDCODE ?  
                            <StaticView 
                                isMobile={isMobile} 
                            />
                        : 
                            <Login
                                isMobile={isMobile} 
                                fetchServer={fetchServer}
                                setToken={setToken}
                                scheduleStartDate= {scheduleStartDate}
                            />
                    :
                    location === '/home' ?
                        <Home 
                            isMobile={isMobile}
                            goToLoginPage={goToLoginPage}
                            user={user}
                            inquiry={inquiry}
                        />
                    :
                    location === '/info' ?
                        <BeforeScheduleView 
                            isMobile={isMobile} 
                            goToLoginPage={goToLoginPage}
                            inquiry={inquiry}
                        />
                    :
                    location === '/choose-gift' ?
                        <ChooseGift 
                            isMobile={isMobile} 
                            goToLoginPage={goToLoginPage}
                            user={user}
                            prefectures={prefectures}
                            fetchServer={fetchServer}
                            inquiry={inquiry}
                        />
                    : 
                    location === '/end' ?
                        <EndViewHome 
                            isMobile={isMobile} 
                            goToLoginPage={goToLoginPage}
                            inquiry={inquiry}
                        />
                    :
                        <NotFound />
                }
            </div>
            <Footer isMobile={isMobile} />
        </>
    )
}

function App ( { history } : AppProps) {
    return (
        <RoutesHook 
            history={history}
        />
    );
}

export default App;