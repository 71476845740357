import React from 'react';
import { Stack, Alert } from '@mui/material';

interface AlertProps {
    isMobile: boolean,
    errorMessage: string,
}

export default function AlertError ({ isMobile, errorMessage } : AlertProps) {
    return (
        <Stack sx={{ width: isMobile ? '322px' : '500px', height: 'auto'}} spacing={2}>
            <Alert severity="error" style={{backgroundColor: '#FFEAEA', border: '1px solid #FF8383', borderRadius: '5px', color: '#FF0000'}}>
                {errorMessage}
            </Alert>
        </Stack>
    )
}