import React from 'react';

interface Props {
    
}

export default function NotFound ({ }: Props) {
    return (
        <h1>Page Not Found</h1>
    )
}