import React, { useEffect, useState, useRef } from 'react';
import BreadCrumbs from '../components/BreadCrumbs';
import PageLayout from '../components/PageLayout';
import Step1 from '../components/steps/Step1';
import Step2 from '../components/steps/Step2';
import Step3 from '../components/steps/Step3';
import Step4 from '../components/steps/Step4';
// import { AlertBackPopup } from '../components/Common';

interface Props {
    isMobile: boolean,
    goToLoginPage: () => void,
    user: any,
    prefectures: any,
    fetchServer: (url: any, method: any, body: any, headers: any, forwardRes: any) => void,
    inquiry: any
}

export default function ChooseGift ({ isMobile, goToLoginPage, user, prefectures, fetchServer, inquiry }: Props) {

    const [ active, setActive ] = useState<number>(1);
    const [ chosen, setChosen ] = useState<number | null>(null);
    const [values, setValues] = useState<any>({
        name: '',
        zipcode: '',
        prefecture_id: '',
        address1: '',
        address2: '',
        address3: '',
        phone_number: ['', '', ''],
        email: ''
    });
    const [confirmModal, setConfirmModal] = useState<boolean>(false);

    const goToNextPage = () => {
        window.scrollTo(0,0);
        setActive(active + 1);
    }

    const goToPrevPage = () => {
        window.scrollTo(0,0);
        setActive(active - 1);
    }

    // -start- Function for click button back or refresh on browser
    // const closeModal = () => {
    //     setConfirmModal(false);
    // };

    // const leavePage = () => {
    //     goToLoginPage()
        
    // };

    // const urlChangeHandler = (url: any, replace = false) => {
    //     if(replace){
    //         window.history.replaceState({}, "", url);
    //     } else{
    //         window.history.pushState({}, "", url);
    //     }
    // };

    // const urlChangeHandler = (url: any, replace = false) => {
    //     const historyMethod = replace ? "replaceState" : "pushState";
    //     window.history[historyMethod]({}, "", url);
    // };

    // useEffect(() => {
    //     urlChangeHandler(window.location.href);
    //     enterValuesInHiddenInput();
    
    //     return () => setConfirmModal(true);
    // }, []);

    // const hiddenInputRef = useRef<HTMLInputElement>(null);

    // const enterValuesInHiddenInput = () => {
    //     if(hiddenInputRef){
    //         if(hiddenInputRef.current){
    //             hiddenInputRef.current.click();
    //         }
    //     }
    // };

    // useEffect(() => {
    //     enterValuesInHiddenInput();
    
    //     const handlePopState = (event: any) => {
    //       event.preventDefault();
    //     };
    //     window.addEventListener("popstate", handlePopState);
    //     window.history.pushState({ confirmModal: false }, "");
    
    //     return () => {
    //       window.removeEventListener("popstate", handlePopState);
    //     };
    // }, [hiddenInputRef, urlChangeHandler]);

    // useEffect(() => {
    //     const onUnload = (e: any) => {
    //         e.preventDefault();
    //         e.returnValue = '';
    //     }
    //     window.addEventListener("beforeunload", onUnload);
        
    //     return () => {
    //         window.removeEventListener("beforeunload", onUnload);
    //     }
    // }, []);

    const onBackButtonEvent = (e: any) => {
        e.preventDefault();
        if (!confirmModal) {
            if (window.confirm("このページを閉じてもよろしいですか？" + "\n" + "変更内容が保存されない可能性があります。")) { // Are you sure for leave this page? Changes you made may not be saved.
                setConfirmModal(true)
                goToLoginPage()
            } else {
                window.history.pushState(null, "", window.location.pathname);
                setConfirmModal(false)
            }
        }
    }
    
    useEffect(() => {
        const onUnload = (e: any) => {
            e.preventDefault();
            e.returnValue = '';
        }
        
        window.history.pushState(null, "", window.location.pathname);

        window.addEventListener("beforeunload", onUnload); // for button refresh on browser
        window.addEventListener('popstate', onBackButtonEvent); // for button back on browser

        return () => {
            window.removeEventListener("beforeunload", onUnload); // for button refresh on browser
            window.removeEventListener('popstate', onBackButtonEvent); // for button back on browser
        };
    }, []);
    // -end- Function for click button back or refresh on browser

    useEffect(()=>{
        if(!user){
            goToLoginPage();
        }
    }, [user])

    return (
        <PageLayout>
            <BreadCrumbs active={active} />

            {
                active === 1 ?
                    <Step1 
                        isMobile={isMobile} 
                        goToNextPage={goToNextPage} 
                        user={user}
                        chosen={chosen}
                        setChosen={setChosen}
                    />
                :
                active === 2 ?
                    <Step2 
                        isMobile={isMobile} 
                        goToNextPage={goToNextPage} 
                        goToPrevPage={goToPrevPage} 
                        prefectures={prefectures}
                        values={values}
                        setValues={setValues}
                    />
                :
                active === 3 ?
                    <Step3 
                        isMobile={isMobile} 
                        goToNextPage={goToNextPage} 
                        goToPrevPage={goToPrevPage}
                        chosen={chosen}
                        user={user}
                        values={values}
                        prefectures={prefectures}
                        fetchServer={fetchServer}
                    />
                : 
                    <Step4 isMobile={isMobile} goToLoginPage={goToLoginPage} inquiry={inquiry}/>
            }
            {/* {confirmModal && (
                <AlertBackPopup 
                    isMobile={isMobile}
                    open={confirmModal}
                    closeModal={closeModal}
                    leavePage={leavePage}
                />
            )} */}
        </PageLayout>
    )
}